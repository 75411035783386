import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


axios.create({
    baseURL: 'https://api.zhiyileiju.cn/api/v1',
    timeout: 5000,
    // headers: {'X-Custom-Header': 'foobar'}
  });
  //上面都是默认配置
  // 第2步开始封装
  var baseURL = 'https://api.zhiyileiju.cn/api/v1';
  var ua = navigator.userAgent.toLowerCase();
  // if (ua.match(/MicroMessenger/i) == "micromessenger") {
  //     baseURL = 'http://hfdjh.htianwh168.com/api/v1';
  //     // return true;
  // } else {
  //     baseURL = 'https://api.zhiyileiju.cn/api/v1';
  //     // return false;
  // }
  const request = ({
    // axios选项(axios选项去axios.js 官网上找 文档 请求配置 (找需要的))
    url= baseURL+url,
    method = 'get',
    headers,
    params,
    data,
    withCredentials = false
  }) => {
    return new Promise((resolve,reject) => {
      //1. 这是数据处理
      switch (method.toLowerCase()){
        case 'post':
          axios({
            url: baseURL+url,
            method,
            headers,
            params,
            data,
            withCredentials :false,
          }).then( res => resolve(res))
          .catch( err => reject(err))
          break;
        default:
          //get put delete
          axios({
            url: baseURL+url,
            method,
            headers,
            params,
            withCredentials : false
          }).then( res => resolve(res))
            .catch( err => reject(err))    
          break;
      }
      //2. 拦截器（去axios.js官网上去找拦截器）
      // 添加请求拦截器
        axios.interceptors.request.use(function (config) {
          // 在发送请求之前做些什么
          return config;
        }, function (error) {
          // 对请求错误做些什么
          return Promise.reject(error);
        });
  
        // 添加响应拦截器
        axios.interceptors.response.use(function (response) {
          // 对响应数据做点什么
          return response;
        }, function (error) {
          // 对响应错误做点什么
          return Promise.reject(error);           
        });
  
          })
  }
  //导出
  export default request